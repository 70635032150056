import faqs from '../js/faqs.js';
import blog from '../js/blog.js';
import {tabBox} from '../js/tab.js';
import {mobileMenuToggle} from '../js/mobile.js';

jQuery(function($){
    $('.home-hero .slides').slick({
      dots:true,
      asNavFor:'.home-intro .slides',
      autoplay: true,
      autoplaySpeed: 6000,
    });

    $('.home-intro .slides').slick({
      dots:false,
      arrows:false,
      fade:true,
      adaptiveHeight:true,
      swipe:false,
      accessibility:false
    });

    $('.about .gallery-slides').slick({
        dots:false,
        variableWidth: true,
        lazyLoad: 'progressive',
        infinite: true,
        centerMode:true,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                variableWidth: false,
                adaptiveHeight:true,
                infinite: false,
                centerMode:false,
              } 
            }
        ]
    });

    $('.about-hero .hero-slides').slick({
        prevArrow:$('.hero-pager .pager-prev'),
        nextArrow:$('.hero-pager .pager-next')
    });

    $('.about-hero .hero-slides').on('afterChange', function(slick, currentSlide){
        const index = currentSlide.currentSlide + 1;
        const output = index < 10 ? '0'+index : index;

        $('.hero-pager .current').text(output);
    });
    
    $('.info-hero-gallery').slick({
        dots:true
    });
    
    $('.info-gallery-slides').slick({
        dots:true
    });

    $('.home-overview-slider').slick({
        dots:true,
        arrows:false,
        adaptiveHeight:true
    });

    $('.single-hero .slides').slick({
      dots:true,
      arrows:false
    });

    $('.common-testimonials .testimonial-slides').slick({
        dots:true
    });

    const mobileExpand = function(){
      let mql = window.matchMedia('(max-width: 1023px)');
      let menu = document.querySelector('.navbar .site-nav');
  
      if(menu){
          let parents = menu.querySelectorAll('.menu-item-has-children');
  
  
          parents.forEach(function(parent){
              parent.querySelector(':scope > a').addEventListener('click', function(e){
                  e.preventDefault();
                  let submenu = parent.querySelector('ul');
                  let submenuHeight = submenu.scrollHeight;
  
                  if(parent.classList.contains('active')){
                      parent.classList.remove('active');
                      submenu.style.maxHeight = 0;
                  }else{
                      parent.classList.add('active');
                      submenu.style.maxHeight = submenuHeight+'px';
                  }
              });
          });
      }
  }
  
  const toggleDropdown = function(){
      const toggles = document.querySelectorAll('.toggle-dropdown');
      const nodes = document.querySelectorAll('.dropdown a');
  
      if(toggles){
          for(var i = 0; i < toggles.length; i++){
              toggles[i].addEventListener('click', function(e){
                  e.preventDefault();
  
                  this.nextElementSibling.classList.toggle('active');
              });
          }
      }

      if(nodes){
        for(var i = 0; i < nodes.length; i++){
            nodes[i].addEventListener('click', function(e){
                this.parentElement.classList.remove('active');
            });
        }
      }
  }
  
  const desktopNav = function(){
      const parents = document.querySelectorAll('.desktop-nav .menu-item-has-children');
      for(let i = 0; i < parents.length; i++){
          parents[i].querySelector(':scope > a').addEventListener('click', function(e){
              e.preventDefault();
          });
      }
  }

    $('.galleries .gallery-slides').slick({
        dots:false,
        lazyLoad: 'progressive',
        infinite: true
    });

    tabBox('.galleries', function(el){
        console.log($('.gallery-slides', el));
        $('.gallery-slides', el).slick('setPosition');
    });

  mobileExpand();
  toggleDropdown();
  desktopNav();

  mobileMenuToggle();

});