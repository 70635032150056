export function mobileMenuToggle(){
    let menuUtil = document.querySelector('.mobile-util-btn');
    let menu = document.querySelector('.site-nav');

    menuUtil.addEventListener('click', function(e){
        e.preventDefault();

        if(menu.classList.contains('active')){
            menu.classList.remove('active');
            document.querySelector('body').classList.remove('mobile-nav-active');
        }else{
            menu.classList.add('active');
            document.querySelector('body').classList.add('mobile-nav-active');
        }

    });
}