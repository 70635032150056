export function tabBox(el, callback){
    const container = document.querySelector(el);

    if(container){
        const nav = container.querySelectorAll('.tab-nav a');
        const mobileNav = container.querySelector('.tab-nav-mobile');
        const panels = container.querySelectorAll('.tab-panel');
        
        for(let i = 0; i < nav.length; i++){
            nav[i].addEventListener('click', function(e){
                e.preventDefault();

                const navActive = container.querySelector('.tab-nav a.active');
                const panelActive = container.querySelector('.tab-panel.active');
                const target = this.getAttribute('href');

                navActive.classList.remove('active');
                panelActive.classList.remove('active');

                this.classList.add('active');
                container.querySelector(target).classList.add('active');
                if(mobileNav){
                    mobileNav.value = target;
                }

                callback(target);
            });
        }

        if(mobileNav){
            mobileNav.addEventListener('change', function(){
                const target = this.value;
                const panelActive = container.querySelector('.tab-panel.active');
                panelActive.classList.remove('active');

                container.querySelector(target).classList.add('active');
            });
        }
    }
}