const faqs = function(){
    const questions = document.querySelectorAll('.common-faq .faq-question');
    const answers = document.querySelectorAll('.common-faq .faq-answer');

    const faqItems = document.querySelectorAll('.common-faq .faq-item');

    if(questions && answers){
        for(let i = 0; i < questions.length; i++){
            questions[i].addEventListener('click', function(e){
                e.preventDefault();
                const itemHeight = this.nextElementSibling.scrollHeight;

                if(this.parentNode.classList.contains('active')){
                    this.nextElementSibling.style.maxHeight = 0;
                    this.parentNode.classList.remove('active');
                }else{
                    this.nextElementSibling.style.maxHeight = itemHeight+'px';
                    this.parentNode.classList.add('active');
                }

            });
        }
    }
}

faqs();