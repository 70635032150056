const blog = function(){
    jQuery(function($){
        $('.single .post-body .gallery').slick({
            adaptiveHeight:true
        });
    });

    const blogLayout = function(){
        const utils = document.querySelector('.filter-utilbar');
        const defaultLayout = 'posts-grid';
    
        if(utils){
            const grid = utils.querySelector('.ui-grid');
            const list = utils.querySelector('.ui-list');
            const container = document.querySelector('main.blog');
    
            container.classList.add(defaultLayout);
    
            grid.addEventListener('click', function(e){
                console.log('hey');
                e.preventDefault();
    
                this.classList.add('active');
                list.classList.remove('active');
                container.classList.add('posts-grid');
                container.classList.remove('posts-list');
            });
    
            list.addEventListener('click', function(e){
                e.preventDefault();
                
                this.classList.add('active');
                grid.classList.remove('active');
                container.classList.add('posts-list');
                container.classList.remove('posts-grid');
            });
        }
    
    }

    blogLayout();
}

blog(); 